import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";
import axios from "axios";
//assign driver for order


/******Update Folder Instructions from Admin Panel******/
export const updateInstruction=async(id,instruction,type)=>{
    try{
        let data={
            id:id,
            instruction:instruction,
            type:type
        }
        const response=await axios.post(HTTPURL+`/api/Update-Instruction`,data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

        return response?.data;
    }catch(error){

    }
}
export async function AssignDriver(data) {
    try {
        const response = await fetch(HTTPURL + `/api/assign-driver`, {
            method: "POST",
            headers: { "Content-Type": "application/json" ,'Authorization': localStorage.getItem('token')},
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
//change folder/driver status


//assign folder for order
export async function AssignFolder(data) {
    try {
        const response = await fetch(HTTPURL + `/api/assign-folder`, {
            method: "POST",
            headers: { "Content-Type": "application/json",'Authorization': localStorage.getItem('token') },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


  //get all order details

export async function GetAllOrders() {
    try {
         
           const response = await fetch(HTTPURL + `/api/order-list`, {
            method: "GET",
            headers: { "Content-Type": "application/json" ,'Authorization': localStorage.getItem('token')},
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}



//get order history
export async function GetHistory() {
  try {
    
         
        const response = await fetch(HTTPURL + `/api/history-orders`, {
            method: "GET",
            headers: { "Content-Type": "application/json" ,'Authorization': localStorage.getItem('token')},
        });
        const responseData = await response.json();
        return responseData;
 
   
  } catch (error) {
    return error.message;
  }
}

//get Payment history
export async function GetPaymentHistory() {
    try {
      
           
          const response = await fetch(HTTPURL + `/api/payment-history`, {
              method: "GET",
              headers: { "Content-Type": "application/json" ,'Authorization': localStorage.getItem('token')},
          });
          const responseData = await response.json();
          return responseData;
   
     
    } catch (error) {
      return error.message;
    }
  }
/******Update Folder Instructions from Admin Panel******/
export const AddLoad=async(form, str_call)=>{ 

     try{
    
     let response = await axios.post(HTTPURL + str_call , form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              'Authorization': localStorage.getItem('token')
            },
          }
        );
          
           
            return response?.data

          }catch(error) {
            
             return false;
          };
          

}


/******Update Folder Instructions from Admin Panel******/
export const UpdateStatus=async(form, str_call)=>{ 

     try{
    
     let response = await axios.post(HTTPURL + str_call , form,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': localStorage.getItem('token')
            },
          }
        );
          
           
            return response?.data

          }catch(error) {
            
             return false;
          };
          

}




  //get all order details

export async function GetQrcodes() {
    try {
         
           const response = await fetch(HTTPURL + `/api/get-qrcodes`, {
            method: "GET",
            headers: { "Content-Type": "application/json" ,'Authorization': localStorage.getItem('token')},
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

