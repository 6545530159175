import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

import 'react-data-table-component-extensions/dist/index.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderDataTable = ({ columns, data}) => {
    const [searchData, setSeacrhData] = useState('');
    const [filterData, setFilterData] = useState([]);
    

  const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;



    const onSearch = (value) => {
        setSeacrhData(value);
        const searchRes = data.filter((o) =>
            Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(value.toLowerCase())
            )
        );
        setFilterData(searchRes);
    };
    const customStyles = {
        headCells: {
            style: {
                backgroundColor: "#a82928",
                color: "white",
            },
        },
        expanderCell: {
            style: {
                backgroundColor: "#a82928",
                color: "white"
            },
        },
        expanderButton: {
            style: {
                backgroundColor: '#eee',
                borderRadius: '2px',
                transition: '0.25s',
                height: '100%',
                width: '100%',
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:hover:not(:disabled)': {
                    cursor: 'pointer',
                    backgroundColor: "#a82928",
                    color: "#fff"
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: "#a82928",
                    color: "#fff"
                },
                svg: {
                    margin: 'auto',
                },
            },

        },
        expanderRow: {
            style: {
                color: '#000',
                backgroundColor: "#eee",
                padding: "10px 70px",
            },
        },
    };


    const conditionalRowStyles = [
        {
            when: row => row.color == 1,
            style: {
                backgroundColor: '#488102',
                color: '#fff',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: '#c2d5aa',
                    color: '#333',
                },
            },
        },
    ];
 

    const ExpandedComponent = ({ data }) => {
        return (
                <>
                <div className="row">
                    <div class="col-6">
                    <p className="mb-1"><b>Name: </b>{data.name} </p>
                        <p className="mb-1"><b>Pickup Address: </b>{data.pickup_address}</p>
                        <p className="mb-1"><b>Delivery Address: </b>{data.delivery_address}</p>
                         <p className="mb-1"><b>Actual Bags/Wt.:: </b>{data.total_loads}</p>
                    </div>
                    <div class="col-6">
                         <p className="mb-1"><b>Driver Instructions: </b>{data.driver_instructions}</p>
                         <p className="mb-1"><b>Bin #: </b>{data.bin}</p>
                         <p className="mb-1"><b>Estimated Bags/Wt.: </b>{data.estimated_loads}</p>
                         
                    </div>
                     <div class="col-12">{data.action}</div>
                </div>
                </>
                );
    };
    

    return (
            <>
            <div className='searc-bar'>
        
        <input type="search" className="form-control " placeholder='Search here...' onChange={(e) => onSearch(e.target.value)} />
          <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
        console.log(update[1]);
        if(update[0] && update[1]){
                setSeacrhData(true);
        const searchRes = data.filter((item) => {
           return new Date(item.pickupdate).getTime() >= update[0].getTime() &&  new Date(item.pickupdate+" 00:00").getTime() <= update[1].getTime();
       });
        setFilterData(searchRes);
    }else
    {
        setSeacrhData(false);
        setFilterData(data);
        
    }
        
        
      }}
      isClearable={true}
         placeholderText="Pickup Date Range"
    />
            
            </div>
            
            <DataTable
                columns={columns}
                data={searchData ? filterData : data}
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                paginationRowsPerPageOptions={[50, 100, 250]}
                paginationPerPage={100}
                highlightOnHover
                customStyles={customStyles}
                expandableRows
                expandableRowsComponent={ExpandedComponent} 
                conditionalRowStyles={conditionalRowStyles}
            
                />
                </>
            )
}

export default OrderDataTable;
